.login {
  text-align: center;
}

.login-grid {
  min-height: 100vh;
}

.login-card {
  width: 300px;
  height: 150px;
}

.feide-login-button {
  font-weight: bold;
}

.feide-login-icon {
  height: 50px;
}

.cristin-logo {
  width: 500px;
  height: 500px;
}
